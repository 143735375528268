<template>
  <v-app-bar class="app-header">
    <div class="app-header__logo" @click="openHomePage">
      <v-fade-transition>
        <img :src="logo" :class="{ mini: !isHomePage }" alt="Logo" key="logo" />
      </v-fade-transition>
    </div>
    <div class="app-header__title" v-if="pageTitle">
      {{ pageTitle }}
    </div>
    <div class="app-header__actions">
      <view-toggle-button class="mr-1"/>
      <open-basket-button />
    </div>
  </v-app-bar>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";

import OpenBasketButton from "@/components/basket/OpenBasketButton.vue";

import ViewToggleButton from "@/components/home/ViewToggleButton.vue";

export default {
  name: "AppHeader",
  components: {
    OpenBasketButton,
    ViewToggleButton,
  },
  props: {
    title: String,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isHomePage = computed(() => route.path === "/");

    const pageTitle = computed(() => {
      if (isHomePage.value) return null;
      return route.meta?.title;
    });

    const logo = computed(() => {
      const logoSvg = isHomePage.value ? "logo" : "logo-mini";
      return require(`@/assets/svg/${logoSvg}.svg`);
    });

    const openHomePage = () => {
      if (isHomePage.value) return;
      router.push("/");
    };

    return {
      logo,
      isHomePage,
      pageTitle,
      openHomePage,
    };
  },
};
</script>

<style lang="scss">
.app-header {
  &.v-toolbar {
    padding: 0 16px;
  }

  &__logo {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    img {
      height: 24px;
      width: auto;
      display: block;

      &.mini {
        height: 36px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #ccc;
  }
}
</style>
