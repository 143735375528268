import { defineStore } from "pinia";

export const useLayoutStore = defineStore("layout", {
  state: () => ({
    homeView: null,
  }),
  getters: {
    currentHomeView: (state) => {
      return state.homeView || 'map';
    }
  },
  actions: {
    setHomeView(view) {
      this.homeView = view;
    },
  },
});