<template>
  <v-btn
    v-if="isButtonVisible"
    variant="text"
    :icon="buttonIcon"
    color="primary"
    @click="toggleView"
  >
  </v-btn>
</template>

<script>
import { computed } from "vue";

import { useRoute } from "vue-router";

import { useDisplay } from 'vuetify';

import { useLayoutStore } from "@/stores/layout";

export default {
  name: 'ViewToggleButton',
  setup() {
    const route = useRoute();
    const layoutStore = useLayoutStore();
    const { smAndDown } = useDisplay();

    const isButtonVisible = computed(() => {
      const isHomePage = route.name === 'home'
      return smAndDown.value && isHomePage;
    })

    const isCurrentViewMap = computed(() => {
      return layoutStore.currentHomeView === 'map'
    })

    const buttonIcon = computed(() => {
      return isCurrentViewMap.value ? 'mdi-list-box' : 'mdi-map';
    })

    const toggleView = () => {
      const nextView = isCurrentViewMap.value ? 'list' : 'map'
      layoutStore.setHomeView(nextView);
    }

    return {
      toggleView,
      buttonIcon,
      isButtonVisible
    }
  }
}
</script>