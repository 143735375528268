import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import pinia from "@/plugins/pinia";

// plugins & libs
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import VueLazyload from "vue-lazyload";

createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(VueLazyload)
  .mount("#app");
