import { defineStore } from "pinia";
import {
  saveStateToLocalStorage,
  getStateFromLocalStorage,
} from "./storeUtils.js";

export const useOrdersStore = defineStore("order", {
  state: () => ({
    orders: getStateFromLocalStorage("user-orders") || [],
    basket: getStateFromLocalStorage("user-basket") || [],
  }),

  actions: {
    addToBasket(product) {
      this.basket.push(product);
      saveStateToLocalStorage("user-basket", this.basket);
    },

    removeFromBasket(productId) {
      this.basket = this.basket.filter(({ id }) => id !== productId);
      saveStateToLocalStorage("user-basket", this.basket);
    },

    updateProductQuantity(productId, quantity) {
      const product = this.basket.find(({ id }) => id === productId);
      if (product) {
        product.quantity = quantity;
        saveStateToLocalStorage("user-basket", this.basket);
      }
    },

    addOrder(order) {
      this.orders.push(order);
      saveStateToLocalStorage("user-orders", this.orders);
    },

    removeOrder(orderId) {
      this.orders = this.orders.filter((order) => order.id !== orderId);
      saveStateToLocalStorage("user-orders", this.orders);
    },
  },
});
