import { createRouter, createWebHistory } from "vue-router";

import AppLayout from "@/components/layout/AppLayout.vue";

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "basket",
        name: "basket",
        meta: {
          title: "Order",
        },
        component: () => import("@/views/BasketView.vue"),
      },
      {
        path: "vendor/:id",
        name: "vendor",
        props: true,
        meta: {
          title: "Store Details",
        },
        component: () => import("@/views/VendorView.vue"),
      },
      {
        path: "/:catchAll(.*)", // Catch-all 404
        name: "not-found",
        meta: {
          title: "Not Found",
        },
        component: () => import("@/views/NotFoundView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
