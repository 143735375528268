import { createI18n } from "vue-i18n";

const getUserLanguage = (locales) => {
  const savedLanguage = localStorage.getItem("language");
  let browserLanguage = navigator.language || "";
  if (browserLanguage.includes("-")) {
    browserLanguage = browserLanguage.split("-").shift();
  }

  let userLanguage = savedLanguage || browserLanguage;

  return locales.includes(userLanguage) ? userLanguage : "en";
};

const getConfig = () => {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return {
    locale: getUserLanguage(Object.keys(messages)),
    fallbackLocale: "en",
    messages,
  };
};

const i18n = createI18n(getConfig());

export default i18n;
