import "vuetify/lib/styles/main.css";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#228B22",
          secondary: "#8B4513",
          inactive: "#F5F5F5"
        },
      },
    },
  },
});

export default vuetify;
